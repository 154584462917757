import React, { useState, useEffect } from "react";
import { Background, Container, Table, Filters, ScrollTable } from "./style";
import api from "../../../services/api";
import InputLabel from "../../../components/InputLabel";
import { format } from "../../../services/DateFormatter";
import Pagination from "../../../components/Pagination";

export default function Acessos() {
  const [Dados, setDados] = useState([]);
  const [Search, setSearch] = useState("");
  const [Action, setAction] = useState("");
  const [DataInicio, setDataInicio] = useState("");
  const [DataFim, setDataFim] = useState("");
  const [Page, setPage] = useState(0);
  const Limit = 10;

  useEffect(() => {
    async function Request() {
      api
        .get(
          `/acessos?nome=${Search}&acao=${Action}&data_inicio=${DataInicio}&data_fim=${DataFim}&page=${Page}&limit=${Limit}`
        )
        .then((response) => {
          setDados(response.data.acessos);
        });
    }
    Request();
  }, [Search, Page, DataInicio, DataFim, Action]);
  return (
    <Background>
      <Container>
        <Filters>
          <InputLabel
            type="search"
            value={Search}
            onChange={(e) => {
              setSearch(e.target.value);
              setPage(0);
            }}
            label="Buscar Operador"
          />
          <InputLabel
            type="search"
            value={Action}
            onChange={(e) => {
              setAction(e.target.value);
              setPage(0);
            }}
            label="Buscar Ação"
          />
          <InputLabel
            type="date"
            value={DataInicio}
            onChange={(e) => setDataInicio(e.target.value)}
            label="Data Início"
          />
          <InputLabel
            type="date"
            value={DataFim}
            onChange={(e) => setDataFim(e.target.value)}
            label="Data Fim"
          />
        </Filters>
        <ScrollTable>
          <Table>
            <thead>
              <tr>
                <td>Operador</td>
                <td>Data</td>
                <td>Ação Realizada</td>
                <td>Descrição</td>
              </tr>
            </thead>
            <tbody>
              {Dados?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.usuario}</td>
                  <td>
                    {new Date(d?.data_hora).toLocaleString("en-US", {
                      timeZone: "America/Sao_Paulo",
                    })}
                  </td>
                  <td>{d?.acao}</td>
                  <td title={d?.descricao}>{d?.descricao}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>
        <Pagination
          Page={Page}
          setPage={setPage}
          Limit={Limit}
          Count={Dados[0]?.count}
        />
      </Container>
    </Background>
  );
}

import styled from "styled-components";

export const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  width: fit-content;
  max-width: 90%;
  align-items: center;
  color: var(--primary-font-color);
  transition: transform 0.5s;
  padding: 0 10px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 0.8em;
  pointer-events: none;
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid var(--second-auxiliary-color);
  /* select */
  outline: none;
  color: var(--primary-font-color);
  padding: 10px;
  font-size: 1em;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  opacity: ${(props) => props.disabled && "0.5"};

  input {
    &:not(:placeholder-shown),
    &:focus {
      border-color: var(--second-auxiliary-color);
    }
  }
  input {
    &:not(:placeholder-shown),
    &:focus {
      + {
        label {
          letter-spacing: 1px;
          height: fit-content;
          color: var(--primary-bg-color);
          background-color: var(--second-auxiliary-color);
          transform: translate(10px, -50%);
        }
      }
    }
  }

  ::-webkit-input-placeholder {
    color: transparent;
  }
  :-moz-placeholder {
    color: transparent;
  }
  ::-moz-placeholder {
    color: transparent;
  }
  :-ms-input-placeholder {
    color: transparent;
  }
  input::placeholder {
    color: transparent;
  }
  textarea::-webkit-input-placeholder {
    color: transparent;
  }
  textarea:-moz-placeholder {
    color: transparent;
  }
  textarea::-moz-placeholder {
    color: transparent;
  }
  textarea:-ms-input-placeholder {
    color: transparent;
  }
  textarea::placeholder {
    color: transparent;
  }
`;

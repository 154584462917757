import React, { useState, useEffect } from "react";
import Texts from "../../../assets/json/texts.json";
import {
  Background,
  Container,
  Table,
  Header,
  Button,
  RemoveIcon,
  ExternalIcon,
  ScrollTable,
} from "./style";
import FileInput from "../../../components/FileInput";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import { useLocation } from "react-router-dom";
import GoBack from "../../../components/GoBack";
import { format } from "../../../services/DateFormatter";
import { formatFilename } from "../../../services/FilenameFormatter";
import { userId } from "../../../services/TokenData";

export default function Anexos() {
  const [Dados, setDados] = useState([]);
  const [ShowFileInput, setShowFileInput] = useState(false);
  const [RealodData, setRealodData] = useState(false);
  const Location = useLocation();

  function deletaAnexo(id, anexo) {
    Swal.fire({
      title: `Deseja excluir o anexo "${anexo}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--deep-carmine-pink)",
      cancelButtonColor: "gray",
      confirmButtonText: "DELETAR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/anexos/${id}`, { data: { id_usuario_acao: userId() } }).then((res) => {
          Toast.fire({
            title: `Anexo deletado.`,
            icon: "success",
          });
          setRealodData((rd) => !rd);
        });
      }
    });
  }

  useEffect(() => {
    api
      .get(
        `/anexos/ocorrencia/${Location?.state?.IdProcesso}/${Location?.state?.Seq}`
      )
      .then((response) => {
        setDados(response.data.anexos_ocorrencia);
      });
  }, [Location?.state?.IdProcesso, Location?.state?.Seq, RealodData]);

  return (
    <Background>
      <GoBack />
      <Container>
        <Header>
          <Button onClick={() => setShowFileInput(true)}>Novo</Button>
          <table>
            <tbody>
              <tr>
                <th>Descrição:</th>
                <td>{Location?.state?.Desc}</td>
              </tr>
              <tr>
                <th>Sequência:</th>
                <td>{Location?.state?.Seq}</td>
              </tr>
            </tbody>
          </table>
        </Header>
        <ScrollTable>
          <Table>
            <thead>
              <tr>
                <th>Seq.</th>
                <th>Título</th>
                <th>Data</th>
                <th>Arquivo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Dados?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.seq_anexo}</td>
                  <td>{d?.titulo_documento}</td>
                  <td>
                    {format({
                      DataInicial: d?.data_inclusao,
                      Formato: "DD/MM/YYYY",
                    })}
                  </td>
                  <td className="td-icon">
                    {formatFilename(d?.nome_arquivo)}
                    <ExternalIcon
                      onClick={() => {
                        window.open(
                          window.location.href.includes("localhost")
                            ? `${Texts.STATIC_URL_DEV}/${d?.url_documento}`
                            : `${Texts.STATIC_URL_DEV}/${d?.url_documento}`
                        );
                      }}
                    />
                  </td>
                  <td>
                    <RemoveIcon
                      onClick={() => deletaAnexo(d?.id, d?.titulo_documento)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>
      </Container>
      <FileInput
        Show={ShowFileInput}
        setShow={setShowFileInput}
        NumProcesso={Location?.state?.NumProcesso}
        IdProcesso={Location?.state?.IdProcesso}
        Seq={Location?.state?.Seq}
        setRealodData={setRealodData}
        Origin={"Anexo"}
      />
    </Background>
  );
}

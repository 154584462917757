import styled from "styled-components";
import { IoClose } from "react-icons/io5";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.Show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 20px 0;
  

  &:before {
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--blur-bg-color);
    position: fixed;
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  padding: 50px 50px 20px;
  border-radius: 5px;
  background-color: var(--secondary-bg-color);
  gap: 20px;
  max-width: 35%;
  width: 100%;
  height: 100%;
  max-height: 100%;

  .infoSol{
    border: 2px dashed var(--table-primary-color);
    width:100%;
    max-width:85%;
    height:100%;
    max-height:37%;
    margin: 5px 0px;
    padding:10px 10px;
  
    p{
  
    }
      strong{
        font-size:17px;
      }
  }

  @media screen and (max-width: 768px) {
    padding: 50px 20px 20px;
  }
`;

export const Title = styled.h1`
  color: var(--primary-font-color);
  text-align: center;
  
`;

export const Button = styled.button`
  border: none;
  height: 40px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;

export const CloseIcon = styled(IoClose)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 40px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`;

import React from "react";
import { Background, Form, CloseIcon, Button, Title } from "./style";
import InputLabel from "../../../InputLabel";

import TextareaLabel from "../../../TextareaLabel";

export default function Motivo({ Show, setShow, setReload, Reload, Dados }) {
  async function HandleSubmit(e) {
    e.preventDefault();
    Close();
  }

  async function Close() {
    setShow(false);
  }
  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => setShow(false)} />
        <Title>Motivo</Title>
        <TextareaLabel
          value={
            Dados?.situacao === "Aguardando"
              ? "Aguarde a resposta do responsável."
              : Dados?.motivo_situacao
          }
          label="Motivo"
          id={"motivo-solicitacao"}
        />
        <InputLabel type="text" value={Dados?.situacao} label="Situação:" />

        <Button type="submit">Fechar</Button>
      </Form>
    </Background>
  );
}

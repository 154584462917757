import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Background,
  Container,
  Img,
  LogoutIcon,
  Navlink,
  Logo,
  MenuIcon,
  MobileMenu,
  // MobileTitle,
  MobileContainer,
  MobileNavlink,
  Dashboard,
  Solicitacao,
  Usuarios,
  Acesso,
  // Reserva,
  Chat,
} from "./style";
import FiecLogo from "../../assets/images/fiec-logo.svg";
import Routes from "../../assets/json/routes.json";
import Texts from "../../assets/json/texts.json";
import { TokenPerfil } from "../../services/TokenData";
import Notifications from "../Notifications";
import { sse } from "../../services/sse";
import MeuPerfil from "../Modal/MeuPerfil";

export default function Navbar() {
  const [Show, setShow] = useState(false);
  const [ShowModalAlertas, setShowModalAlertas] = useState(false);
  const [Buttons, setButtons] = useState(Texts.STATIC_NAVBAR_BUTTONS.DEFAULT);
  const [OpenMenu, setOpenMenu] = useState(false);
  const [DadosUsuario, setDadosUsuario] = useState([]);
  const Navigate = useNavigate();
  const Location = useLocation();

  const ChangePerfil = useCallback(() => {
    switch (TokenPerfil()) {
      case "GESTOR":
        setButtons(Texts.STATIC_NAVBAR_BUTTONS.GESTOR);
        break;
      case "OPERADOR":
        setButtons(Texts.STATIC_NAVBAR_BUTTONS.OPERADOR);
        break;

      case "DEV":
        setButtons(Texts.STATIC_NAVBAR_BUTTONS.DEV);
        break;

      default:
        setButtons(Texts.STATIC_NAVBAR_BUTTONS.DEFAULT);
        break;
    }
  }, []);

  useEffect(() => {
    setOpenMenu(false);
  }, [Location.pathname]);

  useEffect(() => {
    ChangePerfil();
    setShow(Routes.includes(window.location.pathname));
  }, [Location.pathname, ChangePerfil]);

  window.onstorage = () => {
    ChangePerfil();
  };

  return (
    <>
      {TokenPerfil() && (
        <>
          {/* Desktop */}
          <Background Show={Show} Type={`desktop`}>
            <Container>
              <Logo>
                <Img src={FiecLogo} />
              </Logo>
              <Navlink to={Buttons[0].URL} className="primeiro">
                <Dashboard />
                <p className="dash">{Buttons[0].NOME}</p>
              </Navlink>
              <Navlink to={Buttons[1].URL} className="segundo">
                <Solicitacao />
                <p className="solic">{Buttons[1].NOME}</p>
              </Navlink>
              {/* <Navlink to={Buttons[2].URL} className="terceiro">
                <Reserva />
                <p className="reserv">{Buttons[2].NOME}</p>
              </Navlink> */}
              {TokenPerfil() === "GESTOR" ? (
                <>
                  <Navlink to={Buttons[2].URL} className="quarto">
                    <Usuarios />
                    <p className="users">{Buttons[2].NOME}</p>
                  </Navlink>
                  <Navlink to={Buttons[3].URL} className="quinto">
                    <Acesso />
                    <p className="acess">{Buttons[3].NOME}</p>
                  </Navlink>
                </>
              ) : (
                <></>
              )}
              <Navlink to={Buttons[Buttons.length - 1].URL} className="sexto">
                <Chat />
                <p className="chat">{Buttons[Buttons.length - 1].NOME}</p>
              </Navlink>
              <div className="userContent">
                <Notifications
                  Show={ShowModalAlertas}
                  setShow={setShowModalAlertas}
                />
                <MeuPerfil
                  Show={ShowModalAlertas}
                  setShow={setShowModalAlertas}
                  DadosUsuario={DadosUsuario}
                  setDadosUsuario={setDadosUsuario}
                />

                <LogoutIcon
                  onClick={() => {
                    setDadosUsuario([]);
                    localStorage.removeItem("token");
                    sse.close();
                    Navigate("/");
                  }}
                />
              </div>
            </Container>
          </Background>

          {/* Mobile */}
          <Background Show={Show} Type={`mobile`} OpenMenu={OpenMenu}>
            <Container>
              <Logo>
                <Img src={FiecLogo} />
              </Logo>
              <Notifications
                Show={ShowModalAlertas}
                setShow={setShowModalAlertas}
              />
              <MenuIcon
                OpenMenu={OpenMenu}
                onClick={() => setOpenMenu((op) => !op)}
              >
                <div></div>
                <div></div>
                <div></div>
              </MenuIcon>
            </Container>
            <MobileMenu>
              <MobileContainer OpenMenu={OpenMenu}>
              <MobileNavlink to={Buttons[0].URL} className="primeiro">
                <Dashboard />
                <p className="dash">{Buttons[0].NOME}</p>
              </MobileNavlink>
              <MobileNavlink to={Buttons[1].URL} className="segundo">
                <Solicitacao />
                <p className="solic">{Buttons[1].NOME}</p>
              </MobileNavlink>
              {/* <MobileNavlink to={Buttons[2].URL} className="terceiro">
                <Reserva />
                <p className="reserv">{Buttons[2].NOME}</p>
              </MobileNavlink> */}
              {TokenPerfil() === "GESTOR" ? (
                <>
                  <MobileNavlink to={Buttons[2].URL} className="quarto">
                    <Usuarios />
                    <p className="users">{Buttons[2].NOME}</p>
                  </MobileNavlink>
                  <MobileNavlink to={Buttons[3].URL} className="quinto">
                    <Acesso />
                    <p className="acess">{Buttons[3].NOME}</p>
                  </MobileNavlink>
                </>
              ) : (
                <></>
              )}
              <MobileNavlink to={Buttons[Buttons.length - 1].URL} className="sexto">
                <Chat />
                <p className="chat">{Buttons[Buttons.length - 1].NOME}</p>
              </MobileNavlink>
                <span
                  onClick={() => {
                    setDadosUsuario([]);
                    localStorage.removeItem("token");
                    sse.close();
                    Navigate("/");
                  }}
                >
                  Sair
                </span>
              </MobileContainer>
              {/* <MobileTitle
                OpenMenu={OpenMenu}
                onClick={() => setOpenMenu((op) => !op)}
              >
                Processos
              </MobileTitle> */}
            </MobileMenu>
          </Background>
        </>
      )}
    </>
  );
}

import swal from "sweetalert2";

export const Swal = swal.mixin({
  background: "var(--secondary-bg-color)",
  confirmButtonColor: "var(--main-color)",
  color: "var(--primary-font-color)",
});

export const Toast = swal.mixin({
  toast: true,
  position: "top-end",
  background: "var(--secondary-bg-color)",
  confirmButtonColor: "var(--main-color)",
  color: "var(--primary-font-color)",
  showConfirmButton: false,
  timer: 1000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 400px;
  color: var(--secondary-font-color);
  position: absolute;
  top: 70px;
  left: -50px;
  box-shadow: 0px 0px 2px 0px var(--secondary-font-color);
  transition: height 0.5s;
  max-width: 95vw;
  height: ${(props) => (props.Show ? "600px" : "0")};

  @media (max-width: 1400px) {
    top: 90px;
  }

  @media screen and (max-width: 950px) {
    left: -10px;
  }

  &:before {
    content: "";
    position: absolute;
    transform: ${(props) =>
      props.Show ? "translateY(0)" : "translateY(-100vh)"};
    transition: transform 0;
    transition-delay: ${(props) => (props.Show ? "0" : "0.5s")};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent var(--second-auxiliary-color)
      transparent;
    top: -15px;
    left: 50px;

    @media screen and (max-width: 950px) {
      left: 10px;
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.Empty ? "center" : "flex-start")};
  width: 100%;
  overflow: auto;
  position: relative;
  z-index: 1;
  height: 100%;
  background-color: var(--second-auxiliary-color);
  border-radius: 3px;
  @media (max-width: 1050px) {
    width: 90%;
    margin-right: 550px;
  }

  &::-webkit-scrollbar {
    background-color: var(--table-secondary-color);
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ee6c4d7f;
  }
`;

export const BackgroundClose = styled.div`
  display: ${(props) => !props.Show && "none"};
  z-index: -1;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: transparent;
  position: fixed;
  top: 60px;
  left: 0;
`;

export const NotificationContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 2px 10px;
  border-bottom: 2px solid var(--table-border-color);
  font-weight: ${({ seen }) => (!!seen ? "normal" : "bold")};
  color: ${({ seen }) => !!seen || "var(--main-color)"};

  &:hover {
    background-color: var(--first-auxiliary-color);

    span {
      background-color: var(--first-auxiliary-color);
    }

    }
  // h3, span {
  //   color: var(--first-auxiliary-color) !important;
  // }
`;

export const Title = styled.h1`
  display: ${(props) => !props.Empty && "none"};
`;

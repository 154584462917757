import { useState, useEffect } from "react";
import {
  Background,
  Container,
  Table,
  RemoveIcon,
  EditIcon,
  SenhaIcon,
  Filters,
  Button,
  AccessIcon,
  ScrollTable,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import InputLabel from "../../../components/InputLabel";
import Modal from "../../../components/Modal/Dev/Usuario";
import ModalResetSenha from "../../../components/Modal/Gestor/ResetSenha";
import { useNavigate } from "react-router-dom";
import { userId } from "../../../services/TokenData";

export default function Usuarios() {
  const [Dados, setDados] = useState([]);
  const [Search, setSearch] = useState("");
  const [DadosModal, setDadosModal] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const [ShowModalResetSenha, setShowModalResetSenha] = useState(false);
  const [Reload, setReload] = useState(false);
  const Navigate = useNavigate();

  useEffect(() => {
    api.get(`/user/usuarios?nome=${Search}`).then((response) => {
      setDados(response.data.usuarios);
    });
  }, [Search, Reload]);

  async function HandleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "O usuário será excluído permanentemente!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "var(--swal-confirm-button-color)",
      cancelButtonColor: "gray",
      confirmButtonText: "EXCLUIR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/user/usuario?id=${id}`, { id_usuario_acao: userId })
          .then((response) => {
            Toast.fire({ title: response?.data?.success, icon: "success" });
            setReload((r) => !r);
          })
          .catch((err) => {
            Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
          });
      }
    });
  }

  async function accessUser(id, perfil) {
    const data = { id: id, accessUser: true, perfil: perfil };
    try {
      await api.patch(`/accessuser`, { data }).then((response) => {
        if (response.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: "Não foi possível acessar!",
            icon: "warning",
          });
        localStorage.setItem("token", response.data.token);
        switch (perfil) {
          case "GESTOR":
            Navigate("/gestor/dashboard");
            break;

          case "OPERADOR":
            Navigate("/operador/processos");
            break;

          default:
            break;
        }
      });
    } catch (err) {
      Swal.fire({
        title: "Atenção!",
        text: "Não foi possível acessar!",
        icon: "warning",
      });
    }
  }

  return (
    <Background>
      <Container>
        <Filters>
          <Button onClick={() => setShowModal(true)}>Novo</Button>
          <InputLabel
            type="search"
            value={Search}
            onChange={(e) => setSearch(e.target.value)}
            label="Buscar"
          />
        </Filters>
        <ScrollTable>
          <Table>
            <thead>
              <tr>
                <td>Nome</td>
                <td>Email</td>
                <td>Perfil</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {Dados?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.nome}</td>
                  <td>{d?.email}</td>
                  <td>{d?.perfil}</td>
                  <td>
                    <AccessIcon
                      title="Acessar usuário"
                      disabled={d?.perfil === "DEV"}
                      onClick={() =>
                        d?.perfil !== "DEV" && accessUser(d?.id, d?.perfil)
                      }
                    />
                  </td>
                  <td>
                    <SenhaIcon
                      title="Restaurar senha"
                      onClick={() => {
                        setDadosModal(d);
                        setShowModalResetSenha(true);
                      }}
                    />
                  </td>
                  <td>
                    <EditIcon
                      title="Editar"
                      onClick={() => {
                        setDadosModal(d);
                        setShowModal(true);
                      }}
                    />
                  </td>
                  <td>
                    <RemoveIcon
                      title="Excluir"
                      onClick={() => {
                        HandleDelete(d?.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>
      </Container>
      <Modal
        Show={ShowModal}
        setShow={setShowModal}
        Dados={DadosModal}
        setDados={setDadosModal}
        setReload={setReload}
      />
      <ModalResetSenha
        Show={ShowModalResetSenha}
        setShow={setShowModalResetSenha}
        Dados={DadosModal}
        setDados={setDadosModal}
        setReload={setReload}
      />
    </Background>
  );
}

import styled from "styled-components";
import { BsMoonStarsFill } from "react-icons/bs";
import { FaSun } from "react-icons/fa";

export const SunIcon = styled(FaSun)`
  color: var(--gunmetal-color);
  position: absolute;
  bottom: 12px;
  left: 12px;
  font-size: 20px;
  transition: transform 0.5s;
`;

export const MoonIcon = styled(BsMoonStarsFill)`
  color: var(--azure);
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 20px;
  transition: transform 0.5s, font-size 0.2s;
  transform: rotate(180deg);
`;

export const Container = styled.div`
  width: 100px;
  height: 100px;
  position: fixed;
  bottom: -50px;
  left: -50px;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  transform: ${(props) => `rotate(${props.Angle}deg)`};
  z-index: 1;

  @media print {
    display: none;
  }

  &:hover {
    ${SunIcon},${MoonIcon} {
      font-size: 22px;
    }
  }

  div {
    width: 50%;
    height: 50%;
    display: flex;
    position: relative;

    &:nth-child(1) {
      background: conic-gradient(
        from 270deg at 100% 100%,
        var(--gunmetal-color) 0%,
        var(--azure) 25%
      );
      border-radius: 100% 0 0 0;
    }
    &:nth-child(2) {
      border-radius: 0 100% 0 0;
      background-color: var(--azure);
    }
    &:nth-child(3) {
      border-radius: 0 0 0 100%;
      background-color: var(--gunmetal-color);
    }
    &:nth-child(4) {
      background: conic-gradient(
        from 90deg at 0% 0%,
        var(--azure) 0%,
        var(--gunmetal-color) 25%
      );
      border-radius: 0 0 100% 0;
    }
  }
`;

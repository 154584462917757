import React, { useState, useEffect } from "react";
import {
  Background,
  Container,
  Table,
  RemoveIcon,
  EditIcon,
  SenhaIcon,
  Filters,
  Button,
  ScrollTable,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import InputLabel from "../../../components/InputLabel";
import Modal from "../../../components/Modal/Gestor/Usuario";
import ModalResetSenha from "../../../components/Modal/Gestor/ResetSenha";

export default function Usuarios() {
  const [Dados, setDados] = useState([]);
  const [Search, setSearch] = useState("");
  const [DadosModal, setDadosModal] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const [ShowModalResetSenha, setShowModalResetSenha] = useState(false);
  const [Reload, setReload] = useState(false);

  useEffect(() => {
    api.get(`/user/usuarios?nome=${Search}`).then((response) => {
      setDados(response.data.usuarios);
    });
  }, [Search, Reload]);
  async function HandleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "O usuário será excluído permanentemente!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "var(--deep-carmine-pink)",
      cancelButtonColor: "gray",
      confirmButtonText: "EXCLUIR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/user/usuario?id=${id}`)
          .then((response) => {
            Toast.fire({ title: response?.data?.success, icon: "success" });
            setReload((r) => !r);
          })
          .catch((err) => {
            Swal.fire({
              title: "Atenção!",
              text: err.response.data.error,
              icon: "error",
            });
          });
      }
    });
  }

  return (
    <Background>
      <Container>
        <Filters>
          <Button onClick={() => setShowModal(true)}>Novo</Button>
          <InputLabel
            type="search"
            value={Search}
            onChange={(e) => setSearch(e.target.value)}
            label="Buscar"
          />
        </Filters>
        <ScrollTable>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Perfil</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Dados?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.id}</td>
                  <td>{d?.nome}</td>
                  <td>{d?.email}</td>
                  <td>{d?.perfil}</td>
                  <td>
                    <SenhaIcon
                      title="Restaurar senha"
                      onClick={() => {
                        setDadosModal(d);
                        setShowModalResetSenha(true);
                      }}
                    />
                  </td>
                  <td>
                    <EditIcon
                      title="Editar"
                      onClick={() => {
                        setDadosModal(d);
                        setShowModal(true);
                      }}
                    />
                  </td>
                  <td>
                    <RemoveIcon
                      title="Excluir"
                      onClick={() => {
                        HandleDelete(d?.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>
      </Container>
      <Modal
        Show={ShowModal}
        setShow={setShowModal}
        Dados={DadosModal}
        setDados={setDadosModal}
        setReload={setReload}
      />
      <ModalResetSenha
        Show={ShowModalResetSenha}
        setShow={setShowModalResetSenha}
        Dados={DadosModal}
        setDados={setDadosModal}
        setReload={setReload}
      />
    </Background>
  );
}

import React, { useState, useEffect } from "react";
import { Background, Form, Title, Button, CloseIcon } from "./style";
import InputLabel from "../../InputLabel";
import { Swal } from "../../../services/SwalTemplates";
import { userId } from "../../../services/TokenData";
import TextareaLabel from "../../TextareaLabel";
import api from "../../../services/api";
import { format } from "../../../services/DateFormatter";

export default function SolicitacaoTempo({
    Show,
    setShow,
    DadosOcorrencia,
    Reload,
    setReload,
    DadosProcesso,
}) {
    const [Motivo, setMotivo] = useState("");
    const [QuantDias, setQuantDias] = useState(null);
    // const [IdSolicitante, setIdSolicitante] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [IdDestinatario, setIdDestinatario] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [DataPrevista, setDataPrevista] = useState(
        format({ DataConclusao: new Date(), format: "YYYY-MM-DD" })
    );
    useEffect(() => {
        setDataPrevista(DadosOcorrencia?.data_prevista);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Show]);

    async function HandleSubmit(e) {
        e.preventDefault();
        const data = {
            justificativa_solicitacao: Motivo,
            quantidade_dias: Number(QuantDias),
            id_processo: DadosOcorrencia.id_processo,
            id_solicitante: userId(),
            id_ocorrencia: DadosOcorrencia.seq_ocorrencia,
            id_destinatario: DadosProcesso.responsavel_id,
        };
        api
            .post(`/solicitacoes`, { data })
            .then((response) => {
                Swal.fire({
                    title: "Sucesso!",
                    text: response?.data?.success,
                    icon: "success",
                    confirmButtonColor: "var(--main-color)",
                });
            })
            .catch((e) => {
                Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
            });
        api.post(`/user/mail_solicitacao/${"operador"}`, { data })
        Close();
        setReload(!Reload);
    }
    async function Close() {
        setMotivo("");
        setIdDestinatario(0);
        setQuantDias(0);
        setShow(false);
    }
    return (
        <Background Show={Show}>
            <Form onSubmit={HandleSubmit}>
                <CloseIcon onClick={() => Close()} />
                <Title>Solicitação de Novo Prazo</Title>
                <TextareaLabel
                    value={Motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                    label="Motivo"
                    id={"motivo-solicitacao"}
                    required
                    maxLength={130}
                />
                <InputLabel
                    type="number"
                    value={QuantDias}
                    onChange={(e) => setQuantDias(e.target.value)}
                    label="Dias necessarios:"
                    required
                />
                <Button type="submit">Salvar</Button>
            </Form>
        </Background>
    );
}

import { Background, Title, Button, Form, PersonAdd, CloseIcon } from "./style";
import SelectLabel from "../../../components/SelectLabel";
import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { userId } from "../../../services/TokenData";
import { Swal } from "../../../services/SwalTemplates";

export default function AdicionarParticipantes({
  Show,
  setShow,
  IdAgendamento,
  Titulo,
  IdUsuario,
  DataReserva,
  HoraInicio,
  HoraFim,
  IdLocal,
  Observacao,
}) {
  const [Destinatarios, setDestinatarios] = useState(null);
  const [IdDestinatarios, setIdDestinatarios] = useState([]);
  const [Selecionados, setSelecionados] = useState([]);
  const [DadosParticipantes, setDadosParticipantes] = useState([]);
  const [Disabled, setDisabled] = useState(false);

  useEffect(() => {
    async function Request() {
      api.get(`/user/destinatarios/${userId()}`).then((response) => {
        setDestinatarios([
          "Selecione:",
          ...response.data.usuarios.map((r) => r.nome),
        ]);
        setDadosParticipantes([...response.data.usuarios]);
      });
    }
    Request();
  }, [Show, DataReserva]);

  async function HandleSubmit(e) {
    e.preventDefault();
    Swal.fire({
      title: "Agendando...",
      html: "Aguarde, por favor...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let data = {
      titulo: Titulo,
      dia: DataReserva,
      hora_inicio: HoraInicio,
      hora_fim: HoraFim,
      observacao: Observacao,
      id_local: IdLocal,
      id_usuario: IdUsuario,
    };

    IdDestinatarios.forEach((destinatario) => {
      api
        .post(`/agendamentos_participantes`, {
          id_agendamento: IdAgendamento,
          id_usuario: destinatario,
        })
        .then((response) => {
          if (!!response.data.success) {
            api.post(`/user/mail_agendamento/${destinatario}`, { data });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    });
    Swal.close();
    Close();
  }

  async function Close() {
    setDisabled(false);
    setShow(false);
    setSelecionados([]);
    setIdDestinatarios([]);
  }

  const aoSelecionarPart = (e) => {
    const partSelecionado = e.target.value;

    for (let dado of DadosParticipantes) {
      if (dado.nome === partSelecionado && !IdDestinatarios.includes(dado.id)) {
        setIdDestinatarios([dado.id, ...IdDestinatarios]);
      }
    }

    setSelecionados((prevEnviarPara) => {
      if (!prevEnviarPara.includes(partSelecionado)) {
        return [partSelecionado, ...prevEnviarPara];
      }
      return prevEnviarPara;
    });
  };

  function removeUserParti(user) {
    const userObj = DadosParticipantes.find((e) => e.nome === user);
    if (!userObj) return;

    const id = userObj.id;
    const name = userObj.nome;

    const indexId = IdDestinatarios.indexOf(id);
    if (indexId === -1) return;

    const newEnviarPara = Selecionados.filter((item) => item !== name);
    const newIdDestinatario = IdDestinatarios.filter((item) => item !== id);
    setIdDestinatarios(newIdDestinatario);
    setSelecionados(newEnviarPara);
  }

  return (
    <>
      <Background Show={Show}>
        <Form onSubmit={HandleSubmit}>
          <CloseIcon onClick={() => Close()} />

          <Title>Adicionar Participantes</Title>

          <div className="participantes">
            <PersonAdd />
            <SelectLabel
              value={IdDestinatarios}
              onChange={aoSelecionarPart}
              defaultValue="Selecione o usuário"
              options={Destinatarios}
              values={Destinatarios}
            />
          </div>
          <div
            style={{
              display: Selecionados.length === 0 ? "none" : "flex",
              width: "80%",
            }}
            className="listaParticipantes"
          >
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {Selecionados.map((e) => (
                <li onClick={() => removeUserParti(e)}>{e.slice(0, 10)}</li>
              ))}
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              gap: "7%",
              justifyContent: "center",
              marginBottom: "2%",
            }}
          >
            <Button type="submit" disabled={Disabled}>
              Salvar
            </Button>
          </div>
        </Form>
      </Background>
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  Background,
  Container,
  Table,
  Header,
  Button,
  RemoveIcon,
  ScrollTable,
} from "./style";
import api from "../../services/api";
import { Swal, Toast } from "../../services/SwalTemplates";
import { useLocation } from "react-router-dom";
import GoBack from "../../components/GoBack";
import { userId } from "../../services/TokenData";
import AdicionarParticipantes from "../../components/Modal/AdicionarParticipantes";

export default function ParticipantesAgendamento() {
  const [Dados, setDados] = useState([]);
  const [ReloadData, setReloadData] = useState(false);
  const [ShowModalAdicionar, setShowModalAdicionar] = useState(false);
  const Location = useLocation();
  const UserId = userId();

  function removerParticipante(id_agendamento, id_usuario, nome) {
    Swal.fire({
      title: `Deseja remover o participante "${nome}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--deep-carmine-pink)",
      cancelButtonColor: "gray",
      confirmButtonText: "DELETAR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/agendamentos_participantes/${id_agendamento}/${id_usuario}`)
          .then((res) => {
            Toast.fire({
              title: `Participante removido.`,
              icon: "success",
            });
            setReloadData((rd) => !rd);
          });
      }
    });
  }

  useEffect(() => {
    api
      .get(`/agendamentos_participantes/${Location?.state?.Id}`)
      .then((response) => {
        setDados(response.data.agendamento_participantes);
      });
  }, [Location?.state?.Id, ReloadData]);

  return (
    <Background>
      <GoBack />
      <Container>
        <Header>
          <Button
            disabled={Location?.state?.IdAgendadoPor !== UserId ? true : false}
            onClick={() => setShowModalAdicionar(true)}
          >
            Novo
          </Button>
          <table>
            <tbody>
              <tr>
                <th>Título: </th>
                <td>{Location?.state?.Titulo}</td>
                <th>Dia: </th>
                <td>{Location?.state?.Dia}</td>
              </tr>
              <tr>
                <th>Horário:</th>
                <td>
                  {`${Location?.state?.HoraInicial.slice(0, 5)} -
                      ${Location?.state?.HoraFinal.slice(0, 5)}`}
                </td>
                <th>Agendado por:</th>
                <td>{Location?.state?.AgendadoPor}</td>
              </tr>
            </tbody>
          </table>
        </Header>
        <ScrollTable>
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Título</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Dados?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.nome}</td>
                  <td>{d?.email}</td>
                  <td>
                    <RemoveIcon
                      disabled={
                        Location?.state?.IdAgendadoPor !== UserId ? true : false
                      }
                      onClick={() =>
                        removerParticipante(
                          Location?.state?.Id,
                          d.id_usuario,
                          d.nome
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>
      </Container>
      <AdicionarParticipantes
        Show={ShowModalAdicionar}
        setShow={setShowModalAdicionar}
        IdAgendamento={Location?.state?.Id}
        Titulo={Location?.state?.Titulo}
        DataReserva={Location?.state?.Dia}
        HoraInicio={Location?.state?.HoraInicial}
        HoraFim={Location?.state?.HoraFinal}
        IdLocal={Location?.state?.IdLocal}
        Observacao={Location?.state?.Observacao}
        IdUsuario={Location?.state?.IdUsuario}
      />
    </Background>
  );
}

import React, { useState, useEffect } from "react";
import { Background, Container, Table, Header, ScrollTable } from "./style";
import api from "../../../services/api";
import { useLocation } from "react-router-dom";
import GoBack from "../../../components/GoBack";
import { userId } from "../../../services/TokenData";
import ModalMotivo from "../../../components/Modal/Operador/Motivo"
import { TruncateString } from "../../../services/TruncateString";


export default function MinhasSolicitacoes() {
  const [Dados, setDados] = useState([]);
  const [ShowModalMotivo, setShowModalMotivo] = useState(false);
  const [Reload, setReload] = useState(false);
  const Location = useLocation();
  const [DadosMotivo, setDadosMotivo] = useState([])

  useEffect(() => {
    api
      .get(`/processos/${Location?.state?.IdProcesso}/${userId()}/solicitacoes`)
      .then((response) => {
        setDados(response.data.solicitacoes_processo);
      });
  }, [Location?.state?.IdProcesso, Reload]);

  function AlertaCor(dados) {
    if (dados?.situacao === "Aprovado") {
      return "G"
    } if (dados?.situacao === "Reprovado") {
      return "R"
    } else {
      return ""
    }
  }

  return (
    <Background>
      <GoBack />
      <Container>
        <Header style={{ display: "flex", justifyContent: "center" }}>
          <h1>Minhas Solicitações - Processo {Location?.state?.NumProcesso}</h1>
        </Header>

        <ScrollTable>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Ocorrência</th>
                <th>Desc. Ocorrência</th>
                <th>Solicitante</th>
                <th>Qtde. Dias</th>
                <th>Justificativa</th>
                <th>Data da Solicitação</th>
                <th>Situação</th>
                <th>Motivo</th>
              </tr>
            </thead>
            <tbody>
              {Dados?.map((d, i) => (
                <tr key={i} >
                  <td>{i + 1}</td>
                  <td>{d?.id_ocorrencia}</td>
                  <td title={d?.descricao}>{TruncateString(d?.descricao, 20)}</td>
                  <td>{d?.solicitante}</td>
                  <td>{d?.quantidade_dias}</td>
                  <td>{d?.justificativa_solicitacao}</td>
                  <td>{d?.data_solicitacao}</td>
                  <td className={AlertaCor(d)}>{d?.situacao}</td>
                  <td onClick={() => { setShowModalMotivo(true); setDadosMotivo({ motivo_situacao: d?.motivo_situacao, situacao: d?.situacao }) }} className="motivo">{d?.situacao !== "Aguardando" ? "Saiba Mais" : "-"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>

        <table className="legenda">
          <tbody>
            <th
              style={{
                backgroundColor: "var(--alerta-vermelho-color)",
                color: "var(--alerta-vermelho-color)",
              }}
            >
              ______
            </th>
            <td>Reprovado</td>
            <th
              style={{
                backgroundColor: "var(--pastel-green)",
                color: "var(--pastel-green)",
              }}
            >
              ______
            </th>
            <td>Aprovado</td>
          </tbody>
        </table>
      </Container>
      <ModalMotivo
        Show={ShowModalMotivo}
        setShow={setShowModalMotivo}
        Reload={Reload}
        setReload={setReload}
        Dados={DadosMotivo}
      />
    </Background>
  );
}

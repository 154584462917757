import styled from "styled-components";
import LoginBg from "../../assets/images/login-bg.jpg";

export const Background = styled.div`
  min-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url(${LoginBg});
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Form = styled.form`
  width: fit-content;
  height: fit-content;
  padding: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--secondary-bg-color);
  gap: 30px;
`;

export const Button = styled.button`
  border: none;
  background-color: var(--main-color);
  color: var(--button-text-color);
  text-transform: uppercase;
  cursor: pointer;
  padding: 15px 30px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;

export const Title = styled.h1`
  color: var(--primary-font-color);
  font-size: 3em;
`;

export const ForgotPassword = styled.div`
  color: var(--primary-font-color);
  border-bottom: 2px solid var(--main-color);
  cursor: pointer;
  transition: border 0.2s, color 0.2s;

  &:hover {
    color: var(--main-color);
    border-bottom: 2px solid transparent;
  }
`;

export const Element = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-color: transparent;

  ::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

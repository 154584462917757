import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    :root{
        /**
        * Paleta escolhida: https://coolors.co/palette/3d5a80-98c1d9-e0fbfc-ee6c4d-293241
        * #3D5A80
        * #98C1D9
        * #E0FBFC
        * #FF99C8 (modificado)
        * #293241
        * #8CD87B
        */
        /** Nome das cores https://www.color-name.com */
        --deep-carmine-pink: #ed3237;
        --anti-flash-white: #F3F3F3;
        --gray: #7b7b7b;
        --philippine-gray: #8F8F8F;
        --azure: #F3FCFF;
        --light-silver: #CEDEDC;
        --dark-cerulean: #155AA9;
        --gunmetal-color: #293241;
        --dark-midnight-blue: #003866;
        --chinese-black: #1d1d1d;
        --mettalic-green: #2d7708;
        --la-salle-green: #087722;
        --pastel-green: #8CD87B;
        --red-salsa: #f4324a;
        --sandy-brown: #fb9c5a;
        --maize: #fcc755;

        --pale-cerulean-50-alpha-color: #98C1D97F;
        --metallic-blue-50-alpha-color: #3D5A807F;

        --main-color: var(--deep-carmine-pink);
        --blur-bg-color: rgba(0, 0, 0, 0.5);

        --alerta-amarelo-color: #fcc755;
        --alerta-vermelho-color: #f4324a;

        --swal-confirm-button-color: #3085d6;
        --swal-cancel-button-color: #d33;

        --button-text-color: var(--anti-flash-white);
    }

    *{
        margin: 0;
        padding: 0;
    }

    html {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    *, *:before, *:after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit;
    }

    body {
        transition: background-color 0.5s, color 0.5s;
        background-color: var(--primary-bg-color);

        --primary-bg-color: var(--azure);
        --secondary-bg-color: var(--light-silver);
        --first-auxiliary-color: var(--dark-cerulean);
        --second-auxiliary-color: var(--gunmetal-color);
        --primary-font-color: var(--gunmetal-color);
        --secondary-font-color: var(--azure);
        --table-primary-color: var(--dark-cerulean);
        --table-secondary-color: var(--pale-cerulean-50-alpha-color);
        --table-border-color: var(--pale-cerulean-50-alpha-color);
        --contato-font-color: var(--azure);
        --chat-scroll-color: var(--metallic-blue-50-alpha-color);
        --arrow-color: var(--deep-carmine-pink);
        --filter-color: #000;
        --filter-text-color: #fff;
        font-family: sans-serif;
    }
    
    body.dark-theme {
        --primary-bg-color: var(--chinese-black);
        --secondary-bg-color: var(--dark-midnight-blue);
        --first-auxiliary-color: var(--dark-midnight-blue);
        --second-auxiliary-color: var(--azure);
        --primary-font-color: var(--anti-flash-white);
        --secondary-font-color: var(--anti-flash-white);
        --table-primary-color: var(--chinese-black);
        --table-secondary-color: var(--metallic-blue-50-alpha-color);
        --table-border-color: var(--metallic-blue-50-alpha-color);
        --contato-font-color: var(--gunmetal-color);
        --chat-scroll-color: var(--pale-cerulean-50-alpha-color);
        --arrow-color: #fff;
        --filter-color: #fff;
        --filter-text-color: #000;
        
        .pagination {
            background-color: var(--chinese-black);
        }

        h1, th, td {
            color: var(--anti-flash-white);
        }
    }
`;

import { Container, Input, Label } from "./style";

const InputLabel = (props) => {
  return (
    <Container disabled={props?.disabled} className="container">
      <Input
        {...props}
        type={props?.type}
        value={props?.value}
        autoComplete="on"
        onChange={props?.onChange}
        required={props?.required}
        placeholder=""
        disabled={props?.disabled}
        min={props?.min}
        max={props?.max}
        onClick={props?.onClick}
        onFocus={props?.onFocus}
        defaultValue={props?.defaultValue}
        minLength={props?.minLength}
        maxLength={props?.maxLength}
      />
      <Label>{props?.label}</Label>
    </Container>
  );
};

export default InputLabel;

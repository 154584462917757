import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import api from "../../../services/api";
import { userId } from "../../../services/TokenData";
import { IconPerfil, Container } from "./style";
import { useLocation } from "react-router-dom";

const MeuPerfil = ({ setShow, Show, DadosUsuario, setDadosUsuario }) => {
  const [Carregado, setCarregado] = useState(false);
  const UserId = userId();
  const Location = useLocation();

  useEffect(() => {
    if (DadosUsuario.nome === undefined && UserId !== false) {
      api.get(`/user/usuario?id=${UserId}`).then((response) => {
        setDadosUsuario(response.data.response);
        setCarregado(true);
      });
    }
  });

  useEffect(() => {
    if (Carregado && DadosUsuario && DadosUsuario?.nome) {
      encurtaString(DadosUsuario?.nome);
    }
  }, [Carregado, DadosUsuario]);

  const encurtaString = (nome) => {
    if (nome) {
      const primeiroNome = nome.split(" ");
      return primeiroNome[0];
    } else {
      return "Carregando...";
    }
  };

  useEffect(() => {
    setShow(false);
  }, [Location.pathname, setShow]);

  return (
    <Container>
      <NavLink to={"/perfil"} className="link">
        <IconPerfil />
        <p>
          {!!Carregado ? encurtaString(DadosUsuario?.nome) : "Carregando..."}
        </p>
      </NavLink>
    </Container>
  );
};

export default MeuPerfil;

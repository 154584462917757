import React, { useState, useEffect } from "react";
import { Background, Form, Title, Button, CloseIcon } from "./style";
import InputLabel from "../../../InputLabel";
import SelectLabel from "../../../SelectLabel";
import api from "../../../../services/api";
import { Swal } from "../../../../services/SwalTemplates";
import { userId } from "../../../../services/TokenData";

export default function EditProcesso({
  Show,
  setShow,
  Dados,
  setReload,
  Reload,
}) {
  const [Assunto, setAssunto] = useState("");
  const [DataEntrada, setDataEntrada] = useState("");
  const [DataInicio, setDataInicio] = useState("");
  const [DiasPrevistos, setDiasPrevistos] = useState(0);
  const [DataPrevista, setDataPrevista] = useState("");
  const [DiasAmarelo, setDiasAmarelo] = useState(0);
  const [DiasVermelho, setDiasVermelho] = useState(0);
  const [Responsaveis, setResponsaveis] = useState([]);
  const [IdResponsaveis, setIdResponsaveis] = useState([]);
  const [IdResponsavel, setIdResponsavel] = useState(0);
  const UserId = userId();
  const PorcentagemAmarelo = 0.2;
  const PorcentagemVermelho = 0.1;

  useEffect(() => {
    if (Dados) {
      setAssunto(Dados?.assunto);
      setDataEntrada(Dados?.data_entrada);
      setIdResponsavel(Dados?.responsavel);
      setDataInicio(Dados?.data_inicio);
      setDiasPrevistos(Dados?.dias_previstos);
      setDiasAmarelo(Dados?.dias_para_amarelo);
      setDiasVermelho(Dados?.dias_para_vermelho);
    }
  }, [Dados]);

  useEffect(() => {
    if (DataInicio && DiasPrevistos > 0) {
      let Data = new Date(DataInicio);
      Data.setDate(Data.getDate() + parseInt(DiasPrevistos, 10) + 1);
      if (Data.getFullYear() > 2020) {
        setDataPrevista(
          Data.toLocaleDateString("fr-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        );
      }
    }
  }, [DiasPrevistos, DataInicio]);

  useEffect(() => {
    async function Request() {
      api.get(`/user/operadores`).then((response) => {
        setResponsaveis(response.data.usuarios.map((r) => r.nome));
        setIdResponsaveis(response.data.usuarios.map((r) => r.id));
      });
    }
    Request();
  }, []);

  async function HandleSubmit(e) {
    e.preventDefault();

    const data = {
      numero: Dados?.numero,
      assunto: Assunto,
      responsavel: IdResponsavel,
      situacao: "Executando",
      dias_previstos: DiasPrevistos,
      data_entrada: DataEntrada,
      data_inicio: DataInicio,
      dias_para_amarelo: DiasAmarelo,
      dias_para_vermelho: DiasVermelho,
      id_usuario_acao: UserId,
    };

    api
      .patch(`/processos/${Dados?.id}`, { data })
      .then((response) => {
        Swal.fire({
          title: "Sucesso!",
          text: response?.data?.success,
          icon: "success",
          confirmButtonColor: "var(--main-color)",
        });
        setShow(false);
        setReload(!Reload);
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
      });
  }

  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => setShow(false)} />
        <Title>Editar Processo</Title>
        <InputLabel
          type="text"
          value={Assunto}
          onChange={(e) => setAssunto(e.target.value)}
          label="Assunto"
          required
        />
        <InputLabel
          type="date"
          value={DataEntrada}
          onChange={(e) => setDataEntrada(e.target.value)}
          label="Data Entrada"
          required
        />
        <SelectLabel
          label="Responsável"
          value={IdResponsavel}
          onChange={(e) => setIdResponsavel(e.target.value)}
          defaultValue="Responsável"
          options={Responsaveis}
          values={IdResponsaveis}
        ></SelectLabel>
        <InputLabel
          type="date"
          value={DataInicio}
          onChange={(e) => setDataInicio(e.target.value)}
          label="Data Inicio"
          required
        />
        <InputLabel
          type="number"
          value={DiasPrevistos}
          onChange={(e) => {
            setDiasPrevistos(e.target.value);
            setDiasAmarelo(
              Math.ceil(parseInt(e.target.value, 10) * PorcentagemAmarelo)
            );
            setDiasVermelho(
              Math.ceil(parseInt(e.target.value, 10) * PorcentagemVermelho)
            );
          }}
          label="Dias Previstos"
          required
        />
        <InputLabel
          type="date"
          value={DataPrevista}
          onChange={(e) => setDataPrevista(e.target.value)}
          label="Data Prevista"
          disabled
          required
        />
        <InputLabel
          type="number"
          value={DiasAmarelo}
          onChange={(e) => setDiasAmarelo(e.target.value)}
          label="Dias Alerta Amarelo"
          required
        />
        <InputLabel
          type="number"
          value={DiasVermelho}
          onChange={(e) => {
            setDiasVermelho(
              parseInt(e.target.value, 10) < DiasAmarelo &&
                parseInt(e.target.value, 10) > 0
                ? e.target.value
                : 1
            );
          }}
          label="Dias Alerta Vermelho"
          required
        />
        <Button type="submit">Salvar</Button>
      </Form>
    </Background>
  );
}

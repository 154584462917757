import React, { useState, useEffect } from "react";
import {
  Background,
  Form,
  Button,
  Title,
  ForgotPassword,
  // Element,
} from "./style";
import InputLabel from "../../components/InputLabel";
import api from "../../services/api";
import { useNavigate, useLocation } from "react-router-dom";
import { TokenPerfil } from "../../services/TokenData";
import { Swal } from "../../services/SwalTemplates";
import ReCAPTCHA from "react-google-recaptcha";
// import LoginBg from "../../assets/images/login-bg.jpg"

export default function Login() {
  const [Email, setEmail] = useState("");
  const [Senha, setSenha] = useState("");
  const [Ip, setIp] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [capt, setCapt] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [capt2, setCapt2] = useState(false);
  const Location = useLocation();
  const Navigate = useNavigate();

  useEffect(() => {
    switch (TokenPerfil()) {
      case "GESTOR":
        Navigate("/gestor/dashboard");
        break;

      case "OPERADOR":
        Navigate("/operador/processos");
        break;

      case "DEV":
        Navigate("/gestor/acessos");
        break;

      default:
        break;
    }

    var request = new XMLHttpRequest();

    request.open(
      "GET",
      "https://api.ipdata.co/?api-key=37d126a73230b8fd5ea83c1d299bddf2d41d34c3098f4cc027c6c13d"
    );

    request.setRequestHeader("Accept", "application/json");

    request.onreadystatechange = function () {
      if (this.readyState === 4) {
        const response = JSON.parse(this.responseText);
        setIp(`${response.ip} (${response.city})`);
      }
    };

    request.send();
  }, [Location.pathname, Navigate]);

  async function HandleSubmit(e) {
    e.preventDefault();

    const data = {
      email: Email,
      senha: Senha,
      ip: Ip,
    };

    if (!capt && !window.location.href.includes("localhost")) {
      setCapt2(true);
      setTimeout(() => {
        setCapt2(false);
      }, 4000);
    } else {
      api
        .post("/user/login", { data })
        .then(async (response) => {
          localStorage.setItem("token", response.data.token);
          switch (response.data.user.perfil) {
            case "GESTOR":
              Navigate("/gestor/dashboard");
              break;

            case "OPERADOR":
              Navigate("/operador/processos");
              break;

            case "DEV":
              Navigate("/gestor/acessos");
              break;

            default:
              break;
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Atenção!",
            text: err.response.data.error,
            icon: "warning",
          });
        });
    }
  }

  async function HandleForgotPassword() {
    const email = await Swal.fire({
      title: "Recuperação de Senha",
      input: "email",
      text: "Uma nova senha será enviada para seu email em alguns minutos, verifique o SPAM!",
      inputPlaceholder: "Insira o seu email!",
      confirmButtonText: "ENVIAR",
    });

    if (email?.isConfirmed) {
      const data = { email: email?.value };

      if (data?.email) {
        api
          .post(`/user/recover`, { data })
          .then((response) => {
            Swal.fire(response.data.success, "", "success");
          })
          .catch((err) => {
            Swal.fire({
              title: "Atenção!",
              text: err.response.data.error,
              icon: "warning",
            });
          });
      }
    }
  }

  const reCAPTCHA_TOKEN = "6LeJGQIqAAAAALYK6wg1y9UFu9GXFGbfES0EKbmN";

  return (
    <Background>
      <Form onSubmit={HandleSubmit}>
        <Title>Processos</Title>
        <InputLabel
          label="Email"
          type="email"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputLabel
          label="Senha"
          type="password"
          required
          onChange={(e) => setSenha(e.target.value)}
        />
        <ReCAPTCHA
          Theme={"dark"}
          size={"compact"}
          sitekey={reCAPTCHA_TOKEN}
          onChange={(Val) => setCapt(Val)}
        />
        <ForgotPassword onClick={() => HandleForgotPassword()}>
          Esqueci Minha Senha
        </ForgotPassword>
        <Button type="submit">Entrar</Button>
      </Form>
    </Background>
  );
}

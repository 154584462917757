import styled from "styled-components";
import { HiTrash, HiPencil } from "react-icons/hi";
import { FiPaperclip } from "react-icons/fi";
import { HiClock } from "react-icons/hi";
import { FaGear } from "react-icons/fa6";
import { LuCalendarClock } from "react-icons/lu";

export const Background = styled.div`
  min-width: 99vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 50px;
  position: relative;

  @media (max-width: 1440px) {
    padding-bottom: 1px;
  }
`;

export const Container = styled.div`
  height: fit-content;
  width: 95%;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .info {
    position: sticky;
    width: 100%;
    margin-bottom: 25px;
    height: 50px;
    max-width: 100%;
  }

  .legenda {
    padding-top: 20px;
    border-collapse: separate;
    border-spacing: 10px;
    flex-direction: row;
    font-weight: 100;
    font-size: 17px;
    margin-right: auto;
  }
  .legenda th {
    padding: 0px;
  }

  @media (max-width: 1440px) {
    padding: 15px;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  flex-wrap: wrap;

  .I {
      display: none;
  }

  h1 {
    margin-left: auto;
    margin-right: auto;
  }
    .btnPrazo{
      text-align:center;
      border: none;
      height: 47px;
      border-radius: 5px;
      padding: 10px 40px;
      background-color: var(--main-color);
      color: var(--button-text-color);
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 800;
      transition: transform 0.5s;
      transition: box-shadow 0.5s;
      width: 150px;
      
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const Button = styled.button`
  border: none;
  height: 47px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;
  width:150px;

  

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
  align-self: flex-start;
  width: 100%;
  height: fit-content;
  max-height: 60vh;
  scrollbar-width: none;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--table-primary-color);
  border-radius: 5px;

  .Y {
    background-color: var(--alerta-amarelo-color);
  }

  .R {
    background-color: var(--alerta-vermelho-color);
  }

  .G {
    background-color: var(--pastel-green);
  }
  .O {
    background-color: var(--sandy-brown);
  }
  td,
  th {
    padding: 10px;
  }

  .I {
    display: none;
  }

  tbody {
    tr:hover {
      background-color: var(--table-secondary-color);
    }
  }

  tr {
    border: 1px solid var(--table-border-color);
  }
`;

export const RemoveIcon = styled(HiTrash)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const EditIcon = styled(HiPencil)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const AnexoIcon = styled(FiPaperclip)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;
export const ClockIcon = styled(HiClock)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;
export const ConfigIcon = styled(FaGear)`
  max-width: auto;
  cursor: pointer;
  font-size: 25px;
  margin-left: 3%;
  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;
export const ClockSolicitacao = styled(LuCalendarClock)`
  cursor: pointer;
  font-size:30px;
`;


import React, { useState } from "react";
import { Background, Form, Title, Button, CloseIcon } from "./style";
import SelectLabel from "../../../SelectLabel";
import api from "../../../../services/api";
import { Swal } from "../../../../services/SwalTemplates";
import { userId } from "../../../../services/TokenData";
import TextareaLabel from "../../../TextareaLabel";
import Texts from "../../../../assets/json/texts.json";
import { add } from "../../../../services/DateFormatter";

export default function EditSolicitacao({
  Show,
  setShow,
  DadosSolicitacao,
  setReload,
  Reload,
}) {
  const [Motivo, setMotivo] = useState("");
  const [Situacao, setSituacao] = useState("");
  const UserId = userId();

  async function HandleSubmit(e) {
    e.preventDefault();

    const data = {
      situacao: Situacao,
      motivo_situacao:
        Motivo === "" ? "Não foi especificado o motivo." : Motivo,
      id_solicitacao: DadosSolicitacao.id,
      id_ocorrencia: DadosSolicitacao.id_ocorrencia,
      id_processo: DadosSolicitacao.id_processo,
      id_usuario_acao: UserId,
      id_destinatario: DadosSolicitacao.id_solicitante,
    };

    api.patch(`/solicitacoes/${DadosSolicitacao?.id}`, { data });

    if (Situacao === "Aprovada") {
      if (data.id_ocorrencia === null) {
        api
          .get(`/processos/${DadosSolicitacao?.id_processo}`)
          .then((response) => {
            const dadosProcesso = {
              id: response.data.processos.id,
              assunto: response.data.processos.assunto,
              responsavel: response.data.processos.responsavel,
              situacao: response.data.processos.situacao,
              dias_previstos:
                Number(response.data.processos.dias_previstos) +
                Number(DadosSolicitacao.quantidade_dias),
              data_inicio: response.data.processos.data_inicio,
              data_conclusao: response.data.processos.data_conclusao,
              data_espera: response.data.processos.data_espera,
              dias_para_amarelo: response.data.processos.dias_para_amarelo,
              dias_para_vermelho: response.data.dias_para_vermelho,
              id_usuario_acao: userId(),
            };

            api
              .patch(`/processos/${DadosSolicitacao?.id_processo}`, {
                data: dadosProcesso,
              })
              .catch((e) => {
                console.log(e);
                Swal.fire({
                  title: "Atenção!",
                  text: "Error",
                  icon: "error",
                });
              });
          })
          .catch((e) => {
            console.log(e);
            Swal.fire({
              title: "Atenção!",
              text: "Error",
              icon: "error",
            });
          });
      } else {
        api
          .get(
            `/ocorrencias/${DadosSolicitacao?.id_processo}/${DadosSolicitacao?.id_ocorrencia}`
          )
          .then((response) => {
            const dadosOcorrencia = {
              descricao: response.data.ocorrencias_processo[0].descricao,
              data_conclusao:
                response.data.ocorrencias_processo[0].data_conclusao,
              situacao: response.data.ocorrencias_processo[0].situacao,
              data_prevista: add({
                DataInicial:
                  response.data.ocorrencias_processo[0].data_prevista,
                Valor: DadosSolicitacao.quantidade_dias,
                FormatoValor: "D",
              }),
              id_processo: response.data.ocorrencias_processo[0].id_processo,
              seq_ocorrencia:
                response.data.ocorrencias_processo[0].seq_ocorrencia,
              id_usuario_acao: UserId,
            };

            api
              .patch(
                `/ocorrencias/${DadosSolicitacao?.id_processo}/${DadosSolicitacao?.id_ocorrencia}`,
                { data: dadosOcorrencia }
              )
              .catch((e) => {
                console.log(e);
                Swal.fire({
                  title: "Atenção!",
                  text: "Error",
                  icon: "error",
                });
              });
          });
      }
    }

    api
      .patch(`/solicitacoes/${DadosSolicitacao?.id}`, { data })

      .then((response) => {
        Swal.fire({
          title: "Sucesso!",
          text: response?.data?.success,
          icon: "success",
          confirmButtonColor: "var(--main-color)",
        });

        setShow(false);
        setReload(!Reload);

        setShow(false);
        setReload(!Reload);
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({ title: "Atenção!", text: "Error", icon: "error" });
      });

    api.post(`/user/mail_solicitacao/${"gestor"}`, { data });
    Close();
  }

  async function Close() {
    setMotivo("");
    setSituacao("");
    setShow(false);
  }

  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => setShow(false)} />
        <Title>Editar Solicitação</Title>
        <div className="infoSol">
          <p>
            <strong>Processo: </strong> {DadosSolicitacao.numero}
          </p>
          <p>
            <strong>Ocorrência: </strong>{" "}
            {DadosSolicitacao.id_ocorrencia || "N/D"}
          </p>
          <p>
            <strong>Solicitante: </strong> {DadosSolicitacao.solicitante}
          </p>
          <p>
            <strong>Data da Solicitação: </strong>{" "}
            {DadosSolicitacao.data_solicitacao}
          </p>
          <p>
            <strong>Dias solicitados: </strong>
            {DadosSolicitacao.quantidade_dias}
          </p>
          <p>
            <strong>Data Prevista Antes da Aprovação: </strong>{" "}
            {DadosSolicitacao.data_prevista}
          </p>
          {/* <p>
                        <strong>Data Prevista Após a Aprovação: </strong>{" "}
                        {format({
                            DataInicial: add({
                                DataInicial: format({
                                    DataInicial: DadosSolicitacao.data_prevista,
                                    Formato: "MM/DD/YYYY",
                                }),
                                Valor: DadosSolicitacao.quantidade_dias,
                                FormatoValor: "D",
                            }),
                            Formato: "DD/MM/YYYY",
                        })}
                    </p> */}
          <p>
            <strong>Justificativa: </strong>
            {DadosSolicitacao.justificativa_solicitacao}
          </p>
        </div>

        <SelectLabel
          label="Situação"
          value={Situacao}
          onChange={(e) => setSituacao(e.target.value)}
          defaultValue="Selecione uma situação"
          options={Texts?.STATIC_OPC_SITUACAO_SOLICITACAO}
          values={Texts?.STATIC_OPC_SITUACAO_SOLICITACAO}
          required
        ></SelectLabel>
        <TextareaLabel
          label="Motivo"
          type="text"
          value={Motivo}
          onChange={(e) => setMotivo(e.target.value)}
          required={Situacao === "Aprovada" ? false : true}
          maxLength={180}
        />

        <Button type="submit">Salvar</Button>
      </Form>
    </Background>
  );
}
